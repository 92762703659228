<template>
  <div class="container-fluid mb-3">
    <div class="row">
      <div class="col-12 shadow p-5  bg-white rounded">
        <p class="text-main font-600" style="display: flex">
          Sub Dept
          <span class="text-danger mx-3"
            >***ยกเว้น***
            <b-icon
              icon="info-circle-fill"
              animation="fade"
              variant="twd"
              font-scale="1"
            ></b-icon
          ></span>

          <span
            type="button"
            style="flex: 1; text-align: right; white-space: nowrap;"
            v-html="$svg_icon.arrowd"
            @click="getdatacondition()"
            v-b-toggle.Subdept
          ></span>
        </p>
        <div
          class="mb-3 font-0-8s shadow p-3 row  bg-white rounded"
          :class="{ 'sticky-top': down }"
          v-if="data.select.length > 0"
        >
          <p>SELECT: {{ data.select }}</p>
          <div class="col-12 text-right">
            <button
              type="button"
              class="btn btn-sm bt-main"
              @click="confirm()"
              :disabled="$store.getters['loadingActions/GET_load']"
              v-if="down"
            >
              บันทึก
            </button>
          </div>
        </div>
        <!-- --------------------- -->
        <b-collapse id="Subdept" accordion="my-accordion" role="tabpanel">
          <div class="col-4 mb-2">
            <div class="input-group  ">
              <div class="input-group-prepend">
                <select class="custom-select" v-model="find">
                  <option value="">
                    ALL
                  </option>
                  <option
                    v-for="(e, i) in select_mode"
                    :key="i"
                    :value="e.find"
                  >
                    {{ e.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <b-table
            :empty-text="'ไม่พบข้อมูล / No Data'"
            :fields="fields"
            :items="items"
            outlined
            hover
            show-empty
            responsive
            class="font-0-8s"
          >
            <template #cell(SELECT)="row">
              <input
                type="checkbox"
                :value="row.item.ncat_Code"
                v-model="data.select"
              />
            </template>
          </b-table>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dept",
  data() {
    return {
      down: false,
      mode: "mcat",
      find: "",

      select_mode: [
        // { name: "ค้นหาด้วย FirstName", key: "mcat" },
        // { name: "ค้นหาด้วย LastName", key: "mcat" },
        // { name: "ค้นหาด้วย Email", key: "mcat" },
      ],
      fields: [
        { key: "SELECT", label: "SELECT", thStyle: { width: "20%" } },
        { key: "ncat_Code", label: "CatCode" },
        { key: "catName", label: "CatName" },
      ],
      items: [],
      itemlist: [],
      data: { select: [] },
    };
  },
  watch: {
    find: function() {
      if (this.find == "") {
        this.unSearch();
      } else {
        this.Search();
      }
    },
  },
  mounted() {
    this.getdataconditionbyname();
    this.getdataconditionforfind();
  },
  methods: {
    unSearch() {
      this.items = this.itemlist;
    },
    Search() {
      // console.log(data);
      var results = [];
      if (this.find == "") {
        this.getremarkdetail();
      } else {
        let find = this.find;
        if (this.mode !== "showAll" && this.mode !== "active") {
          find = this.find;
        } else {
          find =
            String(this.find).toUpperCase() == "YES"
              ? 1
              : String(this.find).toUpperCase() == "NO"
              ? 0
              : this.find;
        }
        var toSearch = String(find)
          .trim()
          .toLocaleUpperCase()
          .replace(/ /g, "");
        var objects = this.itemlist;
        for (var i = 0; i < objects.length; i++) {
          // for (var key in objects[i]) {
          var index = String(objects[i][this.mode])
            .trim()
            .toLocaleUpperCase()
            .replace(/ /g, "");
          if (index.indexOf(toSearch) != -1) {
            results.push(objects[i]);
          }
          // }
        }
        this.items = results;
      }
    },
    async getdataconditionbyname() {
      try {
        let data = { name: "CAT_CODE_NOT_IN" };

        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `datacondition/getdataconditionbyname`,
          data,
          1
        );

        if (getAPI.data.length !== 0) {
          let getdata = getAPI.data[0].where_in_notin.split(",");
          for await (var [i, v] of getdata.entries()) {
            this.data.select.push(v);
          }
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async getdataconditionforfind() {
      try {
        let data = { mode: "cat" };
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `datacondition/getdatacondition`,
          data,
          1
        );

        let getdata = getAPI.data;
     
        if (getdata.length !== 0) {
          for await (var [i, v] of getdata.entries()) {
         
            if (v.ncat_Code !== null && v.catName !== null) {
              this.select_mode.push({
                name: v.catName,
                key: "mcat",
                find: v.ncat_Code.replace(/'/g, ""),
              });
            }
          }
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async getdatacondition() {
      try {
        this.down = !this.down;
        let data = { mode: "subcat" };
        if (this.down && this.items.length == 0) {
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `datacondition/getdatacondition`,
            data,
            1
          );
          //   console.log(getAPI.data);
          this.items = getAPI.data;
          this.itemlist = getAPI.data;
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async confirm() {
      try {
        if (this.data.select.length == 0) {
          throw `Please Selected Before Save`;
        }
        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          "คุณต้องการบันทึกหรือไม่? / Do you want to save?"
        );
        if (confirm) {
          let data = {
            name: "CAT_CODE_NOT_IN",
            where_in: this.data.select.join(`','`),
          };

          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `datacondition/savedatacondition`,
            data,
            1
          );
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          this.down = false;
          this.$root.$emit("bv::toggle::collapse", "Subdept");
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>

<style></style>

<template>
  <div class="container-fluid mb-3">
    <div class="row">
      <div class="col-12 shadow p-5  bg-white rounded">
        <p class="text-main font-600" style="display: flex">
          Dept
          <span
            type="button"
            style="flex: 1; text-align: right; white-space: nowrap;"
            v-html="$svg_icon.arrowd"
            @click="getdatacondition()"
            v-b-toggle.Dept
          ></span>
        </p>
        <div
          class="mb-3 font-0-8s shadow p-3 row  bg-white rounded"
          :class="{ 'sticky-top': down }"
          v-if="data.select.length > 0"
        >
          <p>SELECT: {{ data.select }}</p>
          <div class="col-12 text-right">
            <button
              type="button"
              :disabled="$store.getters['loadingActions/GET_load']"
              class="btn btn-sm bt-main"
              @click="confirm()"
              v-if="down"
            >
              บันทึก
            </button>
          </div>
        </div>
        <!-- --------------------- -->
        <b-collapse id="Dept" accordion="my-accordion" role="tabpanel">
          <b-table
            :empty-text="'ไม่พบข้อมูล / No Data'"
            :fields="fields"
            :items="items"
            outlined
            hover
            show-empty
            responsive
            class="font-0-8s"
          >
            <template #cell(SELECT)="row">
              <input
                type="checkbox"
                :value="row.item.ncat_Code"
                v-model="data.select"
              />
            </template>
          </b-table>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dept",
  data() {
    return {
      down: false,
      fields: [
        { key: "SELECT", label: "SELECT", thStyle: { width: "20%" } },
        { key: "ncat_Code", label: "CatCode" },
        { key: "catName", label: "CatName" },
      ],
      items: [],
      data: { select: [] },
    };
  },
  mounted() {
    this.getdataconditionbyname();
  },
  methods: {
    async getdataconditionbyname() {
      try {
        let data = { name: "CAT_CODE_IN" };

        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `datacondition/getdataconditionbyname`,
          data,
          1
        );

        if (getAPI.data.length !== 0) {
          let getdata = getAPI.data[0].where_in_notin.split(",");
          for await (var [i, v] of getdata.entries()) {
            this.data.select.push(v);
          }
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async getdatacondition() {
      try {
        this.down = !this.down;
        let data = { mode: "cat" };
        if (this.down && this.items.length == 0) {
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `datacondition/getdatacondition`,
            data,
            1
          );

          this.items = getAPI.data;
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async confirm() {
      try {
        if (this.data.select.length == 0) {
          throw `Please Selected Before Save`;
        }
        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          "คุณต้องการบันทึกหรือไม่? / Do you want to save?"
        );
        if (confirm) {
          let data = {
            name: "CAT_CODE_IN",
            where_in: this.data.select.join(`','`),
          };
 
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `datacondition/savedatacondition`,
            data,
            1
          );
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
           this.down=false
           this.$root.$emit("bv::toggle::collapse", "Dept");
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>

<style></style>

<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-5 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-8   mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Data Conditions</p>
          </div>
          <div class="col-4 text-right">
            <button type="button" class="btn bt-main" @click="RefreshData()">
              <span v-html="$svg_icon.Arrowclockwise"></span> Refresh Data
            </button>
          </div>
        </div>
        <hr class="m-0 p-0" />
        <div class="row p-3 my-3">
          <div class="col-12">
            <Dept />

            <Subdept />

            <Specialattribute />

            <SKUSTATUS />

            <SKUSTATUSNOTIN />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dept from "@/components/Setting/Dataconditions/Dept.vue";
import Subdept from "@/components/Setting/Dataconditions/Subdept.vue";
import Specialattribute from "@/components/Setting/Dataconditions/Specialattribute.vue";
import SKUSTATUS from "@/components/Setting/Dataconditions/SKUSTATUS.vue";
import SKUSTATUSNOTIN from "@/components/Setting/Dataconditions/SKUSTATUSNOTIN.vue";
export default {
  components: { Specialattribute, Subdept, Dept, SKUSTATUS, SKUSTATUSNOTIN },
  name: "Dataconditions",
  methods: {
    async RefreshData() {
      try {
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `datacondition/refresh`,
          {},
          1
        );
        this.$serviceMain.showSuccessAlert(this, "โปรดรอการรีเฟรชข้อมูลประมาณ 10 นาที / Please wait for 10 minutes to refresh the data.");
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>

<style></style>
